import React from 'react';
import StripeCheckoutButton from './ui/StripeCheckoutButton';

interface TokenBundleProps {
  tier: 'bundle_500' | 'bundle_1000' | 'bundle_2500';
  name: string;
  tokens: number;
  price: number;
  description: string;
}

const bundlePriceIdMapping: Record<string, string> = {
  'bundle_500': process.env.STRIPE_BUNDLE_500_PRICE!,
  'bundle_1000': process.env.STRIPE_BUNDLE_1000_PRICE!,
  'bundle_2500': process.env.STRIPE_BUNDLE_2500_PRICE!,
};

const TokenBundleCard: React.FC<TokenBundleProps> = ({
  name,
  tokens,
  price,
  description,
  tier
}) => {
  return (
    <div className="border rounded-lg p-6 shadow hover:shadow-lg transition duration-300 bg-white dark:bg-gray-800 flex flex-col">
      <p className="text-3xl font-bold mb-2 text-primary-light dark:text-primary-dark">
        {tokens} Tokens
      </p>
      <p className="text-xl font-bold mb-4 text-primary-light dark:text-primary-dark">
        ${price}
      </p>
      <p className="text-gray-600 dark:text-gray-400 mb-6">{description}</p>
      <StripeCheckoutButton 
        tier={tier} 
        priceId={bundlePriceIdMapping[tier]}
        disabled={false}
      />
    </div>
  );
};

export default TokenBundleCard;
