import React from 'react';
import { 
  createBrowserRouter, 
  RouterProvider, 
  Route, 
  createRoutesFromElements,
  Outlet 
} from 'react-router-dom';
import LandingPage from './pages/LandingPage';
import SignupPage from './pages/SignupPage';
import LoginPage from './pages/LoginPage';
import SearchPage from './pages/SearchPage';
import VerifyEmailPage from './pages/VerifyEmailPage';
import PasswordResetPage from './pages/PasswordResetPage';
import UpdatePasswordPage from './pages/UpdatePasswordPage';
import DashboardPage from './pages/DashboardPage';
import ProtectedRoute from './components/ProtectedRoute';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import AccountPage from './pages/AccountPage';
import AboutPage from './pages/AboutPage';
import TermsPage from './pages/TermsPage';
import PrivacyPage from './pages/PrivacyPage';
import TwoFactorAuthPage from './pages/TwoFactorAuthPage';

if (process.env.NODE_ENV === 'production') {
  console.log = () => {};
  console.warn = () => {}; // Optional: disable warnings too
  console.error = () => {}; // Optional: disable errors too
}

// Create a layout component that includes Navbar and Footer
const RootLayout: React.FC = () => {
  return (
    <div className="flex flex-col min-h-screen bg-background-light dark:bg-background-dark">
      <Navbar />
      <main className="flex-grow">
        <Outlet /> {/* This is where child routes will render */}
      </main>
      <Footer />
    </div>
  );
};

const App: React.FC = () => {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route path="/" element={<RootLayout />}>
        <Route index element={<LandingPage />} />
        <Route path="signup" element={<SignupPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/2fa" element={<TwoFactorAuthPage />} />
        <Route path="verify-email" element={<VerifyEmailPage />} />
        <Route path="reset-password" element={<PasswordResetPage />} />
        <Route path="update-password" element={<UpdatePasswordPage />} />

        {/* Protected Routes */}
        <Route element={<ProtectedRoute />}>
          <Route path="search" element={<SearchPage />} />
          <Route path="dashboard" element={<DashboardPage />} />
          <Route path="account" element={<AccountPage />} />
        </Route>

        {/* Template Pages */}
        <Route path="about" element={<AboutPage />} />
        <Route path="terms" element={<TermsPage />} />
        <Route path="privacy" element={<PrivacyPage />} />

        {/* Catch-all Route */}
        <Route 
          path="*" 
          element={
            <h1 className="text-center mt-20 text-4xl text-gray-800 dark:text-gray-200">
              404 - Page Not Found
            </h1>
          } 
        />
      </Route>
    )
  );

  return <RouterProvider router={router} />;
};

export default App;
