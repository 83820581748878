import React, { useContext, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button } from '../components/ui/Button';
import { Input } from '../components/ui/Input';
import { AuthContext } from '../context/AuthContext';

const TwoFactorAuthPage: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { email, password } = location.state || {};
  const { login } = useContext(AuthContext);
  
  const [token, setToken] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');
    setLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_ENVIRONMENT_DOMAIN}/user/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email,
          password,
          token,
        }),
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.error || 'Failed to verify 2FA token');
      }

      // Call the login function from AuthContext to set the user state
      login(data.token, {
        tokens_remaining: data.user.tokens_remaining,
        subscription_tier: data.user.subscription_tier,
      });

      navigate('/search'); // Redirect to the desired page after successful login
    } catch (err: any) {
      console.error('2FA error:', err);
      setError(err.message || 'Failed to verify 2FA token. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-light dark:bg-gradient-dark">
      <div className="max-w-md w-full space-y-8 p-8 bg-white dark:bg-gray-800 rounded-lg shadow-lg">
        <h2 className="mt-6 text-3xl font-extrabold text-secondary-light dark:text-secondary-dark">Enter 2FA Code</h2>
        <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
          <Input
            type="text"
            placeholder="Enter your 2FA code"
            value={token}
            onChange={(e) => setToken(e.target.value)}
            required
            className="mb-2 w-full"
          />
          {error && <p className="text-red-500 text-sm">{error}</p>}
          <Button type="submit" className="w-full bg-primary-light dark:bg-primary-dark hover:bg-primary-light dark:hover:bg-primary-dark text-white" disabled={loading}>
            {loading ? 'Verifying...' : 'Verify 2FA'}
          </Button>
        </form>
      </div>
    </div>
  );
};

export default TwoFactorAuthPage;