import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from '../components/ui/Button';
import { Input } from '../components/ui/Input';
import FavoritesPopup from '../components/FavoritesPopup'; // Ensure import if needed

const LandingPage: React.FC = () => {
  const navigate = useNavigate();
  const [pricingOption, setPricingOption] = useState<'subscription' | 'bundle'>('subscription');

  const handleSearch = (e: React.FormEvent) => {
    e.preventDefault();
    navigate(`/search`);
  };

  return (
    <div className="min-h-screen bg-gradient-light dark:bg-gradient-dark text-gray-800 dark:text-gray-200">
      <main className="flex-1">
        {/* Hero Section */}
        <section className="w-full py-12 md:py-24 lg:py-32 bg-white dark:bg-gray-800"> 
          <div className="container mx-auto px-4 md:px-6">
            <div className="flex flex-col items-center space-y-4 text-center">
              <div className="space-y-2">
                <h1 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl lg:text-6xl">
                  Find All Game Assets On The Internet
                </h1>
                <p className="mx-auto max-w-[700px] text-gray-600 dark:text-gray-400 md:text-xl">
                  Indie Dev? Spending hours finding the right asset? Search, filter, and find exactly what you need using our AI search.
                </p>
              </div>
              <div className="w-full max-w-sm space-y-2">
                <form className="flex space-x-2" onSubmit={handleSearch}>
                  <Input
                    className="max-w-lg flex-1"
                    placeholder="Search game assets..."
                    type="text"
                  />
                  <Button type="submit" className="bg-primary-light dark:bg-primary-dark hover:bg-primary-light dark:hover:bg-primary-dark text-white">
                    Search
                  </Button>
                </form>
              </div>
            </div>
          </div>
        </section>

        {/* Updated Pricing Section */}
        <section id="pricing" className="w-full py-12 md:py-24 lg:py-32 bg-gradient-light dark:bg-gradient-dark">
          <div className="container mx-auto px-4 md:px-6">
            <h2 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl text-center mb-6 text-secondary-light dark:text-secondary-dark">Choose Your Plan</h2>
            
            {/* Pricing Toggle */}
            <div className="flex justify-center mb-8">
              <button
                onClick={() => setPricingOption('subscription')}
                className={`px-4 py-2 rounded-l ${pricingOption === 'subscription' ? 'bg-primary-light text-white' : 'bg-gray-200 dark:bg-gray-700 text-gray-600 dark:text-gray-300'}`}
              >
                Subscriptions
              </button>
              <button
                onClick={() => setPricingOption('bundle')}
                className={`px-4 py-2 rounded-r ${pricingOption === 'bundle' ? 'bg-primary-light text-white' : 'bg-gray-200 dark:bg-gray-700 text-gray-600 dark:text-gray-300'}`}
              >
                Token Bundles
              </button>
            </div>

            {/* Pricing Cards */}
            {pricingOption === 'subscription' ? (
              <div className="grid gap-8 lg:grid-cols-3 max-w-5xl mx-auto">
                {/* Free Plan */}
                <div className="flex flex-col p-6 bg-white dark:bg-gray-700 rounded-lg shadow-lg transition-transform hover:scale-105 h-full">
                  <h3 className="text-2xl font-bold mb-4 text-secondary-light dark:text-secondary-dark">Free Plan</h3>
                  <p className="text-4xl font-bold mb-6 text-primary-light dark:text-primary-dark">Free</p>
                  <ul className="mb-6 space-y-2 text-gray-700 dark:text-gray-300 flex-grow">
                    <li className="flex items-center">
                      <svg className="w-5 h-5 text-primary-light dark:text-primary-dark mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                      </svg>
                      150 Tokens Monthly
                    </li>
                    <li className="flex items-center">
                      <svg className="w-5 h-5 text-primary-light dark:text-primary-dark mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                      </svg>
                      No Expiry
                    </li>
                  </ul>
                  <button
                    onClick={() => navigate('/signup')} // Navigate to the sign-up page
                    className="w-full p-2 bg-primary-light dark:bg-primary-dark text-white rounded hover:bg-primary-light dark:hover:bg-primary-dark transition duration-300"
                  >
                    Sign Up for Free
                  </button>
                </div>

                {/* Paid Plan */}
                <div className="flex flex-col p-6 bg-white dark:bg-gray-700 rounded-lg shadow-lg transition-transform hover:scale-105 h-full">
                  <h3 className="text-2xl font-bold mb-4 text-secondary-light dark:text-secondary-dark">Paid Plan</h3>
                  <p className="text-4xl font-bold mb-6 text-primary-light dark:text-primary-dark">$3</p>
                  <ul className="mb-6 space-y-2 text-gray-700 dark:text-gray-300 flex-grow">
                    <li className="flex items-center">
                      <svg className="w-5 h-5 text-primary-light dark:text-primary-dark mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                      </svg>
                      1000 Tokens Monthly
                    </li>
                    <li className="flex items-center">
                      <svg className="w-5 h-5 text-primary-light dark:text-primary-dark mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                      </svg>
                      No Expiry
                    </li>
                  </ul>
                  <button
                    onClick={() => navigate('/dashboard')} // Navigate to the sign-up page
                    className="w-full p-2 bg-primary-light dark:bg-primary-dark text-white rounded hover:bg-primary-light dark:hover:bg-primary-dark transition duration-300"
                  >
                    Buy
                  </button>
                </div>

                {/* Premium Plan */}
                <div className="flex flex-col p-6 bg-white dark:bg-gray-700 rounded-lg shadow-lg transition-transform hover:scale-105 h-full">
                  <h3 className="text-2xl font-bold mb-4 text-secondary-light dark:text-secondary-dark">Premium Plan</h3>
                  <p className="text-4xl font-bold mb-6 text-primary-light dark:text-primary-dark">$5</p>
                  <ul className="mb-6 space-y-2 text-gray-700 dark:text-gray-300 flex-grow">
                    <li className="flex items-center">
                      <svg className="w-5 h-5 text-primary-light dark:text-primary-dark mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                      </svg>
                      5000 Tokens Monthly
                    </li>
                    <li className="flex items-center">
                      <svg className="w-5 h-5 text-primary-light dark:text-primary-dark mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                      </svg>
                      No Expiry
                    </li>
                  </ul>
                  <button
                    onClick={() => navigate('/dashboard')} // Navigate to the sign-up page
                    className="w-full p-2 bg-primary-light dark:bg-primary-dark text-white rounded hover:bg-primary-light dark:hover:bg-primary-dark transition duration-300"
                  >
                    Buy
                  </button>
                </div>
              </div>
            ) : (
              <div className="grid gap-8 lg:grid-cols-3 max-w-5xl mx-auto">
                {/* Bundle 1: 500 Tokens for $3 */}
                <div className="flex flex-col p-6 bg-white dark:bg-gray-700 rounded-lg shadow-lg transition-transform hover:scale-105">
                  <h3 className="text-2xl font-bold mb-4 text-secondary-light dark:text-secondary-dark">500 Tokens</h3>
                  <p className="text-4xl font-bold mb-6 text-primary-light dark:text-primary-dark">$3</p>
                  <ul className="mb-6 space-y-2 text-gray-700 dark:text-gray-300 flex-grow">
                    <li className="flex items-center">
                      <svg className="w-5 h-5 text-primary-light dark:text-primary-dark mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                      </svg>
                      500 Tokens
                    </li>
                    <li className="flex items-center">
                      <svg className="w-5 h-5 text-primary-light dark:text-primary-dark mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                      </svg>
                      Immediate Token Access
                    </li>
                  </ul>
                  <button
                    onClick={() => navigate('/dashboard')} // Navigate to the sign-up page
                    className="w-full p-2 bg-primary-light dark:bg-primary-dark text-white rounded hover:bg-primary-light dark:hover:bg-primary-dark transition duration-300"
                  >
                    Buy
                  </button>
                </div>

                {/* Bundle 2: 1000 Tokens for $5 */}
                <div className="flex flex-col p-6 bg-white dark:bg-gray-700 rounded-lg shadow-lg transition-transform hover:scale-105">
                  <h3 className="text-2xl font-bold mb-4 text-secondary-light dark:text-secondary-dark">1000 Tokens</h3>
                  <p className="text-4xl font-bold mb-6 text-primary-light dark:text-primary-dark">$5</p>
                  <ul className="mb-6 space-y-2 text-gray-700 dark:text-gray-300 flex-grow">
                    <li className="flex items-center">
                      <svg className="w-5 h-5 text-primary-light dark:text-primary-dark mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                      </svg>
                      1000 Tokens
                    </li>
                    <li className="flex items-center">
                      <svg className="w-5 h-5 text-primary-light dark:text-primary-dark mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                      </svg>
                      Immediate Token Access
                    </li>
                  </ul>
                  <button
                    onClick={() => navigate('/dashboard')} // Navigate to the sign-up page
                    className="w-full p-2 bg-primary-light dark:bg-primary-dark text-white rounded hover:bg-primary-light dark:hover:bg-primary-dark transition duration-300"
                  >
                    Buy
                  </button>
                </div>

                {/* Bundle 3: 2500 Tokens for $10 */}
                <div className="flex flex-col p-6 bg-white dark:bg-gray-700 rounded-lg shadow-lg transition-transform hover:scale-105">
                  <h3 className="text-2xl font-bold mb-4 text-secondary-light dark:text-secondary-dark">2500 Tokens</h3>
                  <p className="text-4xl font-bold mb-6 text-primary-light dark:text-primary-dark">$10</p>
                  <ul className="mb-6 space-y-2 text-gray-700 dark:text-gray-300 flex-grow">
                    <li className="flex items-center">
                      <svg className="w-5 h-5 text-primary-light dark:text-primary-dark mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                      </svg>
                      2500 Tokens
                    </li>
                    <li className="flex items-center">
                      <svg className="w-5 h-5 text-primary-light dark:text-primary-dark mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                      </svg>
                      Immediate Token Access
                    </li>
                  </ul>
                  <button
                    onClick={() => navigate('/dashboard')} // Navigate to the sign-up page
                    className="w-full p-2 bg-primary-light dark:bg-primary-dark text-white rounded hover:bg-primary-light dark:hover:bg-primary-dark transition duration-300"
                  >
                    Buy
                  </button>
                </div>
              </div>
            )}
          </div>
        </section>

        {/* FAQ Section */}
        <section id="faq" className="w-full py-12 md:py-24 lg:py-32 bg-white dark:bg-gray-800">
          <div className="container mx-auto px-4 md:px-6">
            <h2 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl text-center mb-8 text-secondary-light dark:text-secondary-dark">Frequently Asked Questions</h2>
            <div className="grid gap-6 lg:grid-cols-2">
              <div>
                <h3 className="text-xl font-bold mb-2 dark:text-white">Question 1</h3>
                <p className="text-gray-600 dark:text-gray-400">Answer 1.</p>
              </div>
              <div>
                <h3 className="text-xl font-bold mb-2 dark:text-white">Question 2</h3>
                <p className="text-gray-600 dark:text-gray-400">Answer 2.</p>
              </div>
              <div>
                <h3 className="text-xl font-bold mb-2 dark:text-white">Can I use the assets I find for commercial projects?</h3>
                <p className="text-gray-600 dark:text-gray-400">Usage rights depend on the individual asset's license. We provide licensing information for each asset to help you make informed decisions.</p>
              </div>
              <div>
                <h3 className="text-xl font-bold mb-2 dark:text-white">What payment methods do you accept?</h3>
                <p className="text-gray-600 dark:text-gray-400">We process payments with Stripe. We accept major credit cards and any supported payment type in Stripe.</p>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default LandingPage;
