import React from 'react';

const TermsPage: React.FC = () => {
  return (
    <div className="w-full min-h-screen px-24 py-12 bg-gradient-light dark:bg-gradient-dark text-gray-800 dark:text-gray-200">
      <h1 className="text-3xl font-bold mb-6">Terms of Service</h1>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2">1. Introduction</h2>
        <p>
          Welcome to <strong>Game Asset Finder</strong> ("we," "us," "our"). These Terms of Service ("Terms") govern your access to and use of our website <a href="https://www.gameassetfinder.com" className="text-primary-light dark:text-primary-dark underline">www.gameassetfinder.com</a> (the "Site"). By accessing or using our Site, you agree to be bound by these Terms.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2">2. Eligibility</h2>
        <p>
          You must be at least 13 years old to use our services. By agreeing to these Terms, you represent and warrant that you are at least 13 years old.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2">3. User Accounts</h2>

        <h3 className="text-xl font-semibold mt-4 mb-2">3.1. Registration</h3>
        <p>
          To access certain features of our Site, you must register for an account. You agree to provide accurate, current, and complete information during registration.
        </p>

        <h3 className="text-xl font-semibold mt-4 mb-2">3.2. Account Security</h3>
        <p>
          You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account. Notify us immediately of any unauthorized use.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2">4. Subscriptions and Payments</h2>

        <h3 className="text-xl font-semibold mt-4 mb-2">4.1. Subscription Plans</h3>
        <p>
          We offer subscription plans with different durations:
        </p>
        <ul className="list-disc list-inside mb-4">
          <li><strong>1 Month</strong></li>
          <li><strong>6 Months</strong></li>
          <li><strong>Yearly</strong></li>
        </ul>
        <p>
          Subscriptions provide access to our AI-based asset scraping services and token bundles.
        </p>

        <h3 className="text-xl font-semibold mt-4 mb-2">4.2. Token Bundles</h3>
        <p>
          In addition to subscriptions, you can purchase token bundles:
        </p>
        <ul className="list-disc list-inside mb-4">
          <li><strong>500 Tokens:</strong> $3</li>
          <li><strong>1000 Tokens:</strong> $5</li>
          <li><strong>2500 Tokens:</strong> $10</li>
        </ul>
        <p>
          Tokens are used to perform asset searches on our Site.
        </p>

        <h3 className="text-xl font-semibold mt-4 mb-2">4.3. Payment Processing</h3>
        <p>
          All payments are processed securely through Stripe. By providing payment information, you consent to Stripe’s <a href="https://stripe.com/privacy" className="text-primary-light dark:text-primary-dark underline">Privacy Policy</a> and <a href="https://stripe.com/legal" className="text-primary-light dark:text-primary-dark underline">Terms of Service</a>.
        </p>

        <h3 className="text-xl font-semibold mt-4 mb-2">4.4. Billing</h3>
        <p>
          Subscriptions are billed in advance based on the selected duration. Token bundles are billed at the time of purchase.
        </p>

        <h3 className="text-xl font-semibold mt-4 mb-2">4.5. Refunds</h3>
        <p>
          All sales are final. We do not issue refunds for subscription fees or token bundle purchases. Please ensure you understand the service before purchasing.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2">5. Use of Services</h2>

        <h3 className="text-xl font-semibold mt-4 mb-2">5.1. Allowed Use</h3>
        <p>
          You may use our services solely for lawful purposes and in accordance with these Terms.
        </p>

        <h3 className="text-xl font-semibold mt-4 mb-2">5.2. Prohibited Use</h3>
        <p>
          You agree not to:
        </p>
        <ul className="list-disc list-inside mb-4">
          <li>Use the Site for any illegal or unauthorized purpose.</li>
          <li>Attempt to gain unauthorized access to our systems or networks.</li>
          <li>Distribute malware or harmful computer code.</li>
          <li>Infringe upon the intellectual property rights of others.</li>
        </ul>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2">6. Intellectual Property</h2>

        <h3 className="text-xl font-semibold mt-4 mb-2">6.1. Our Content</h3>
        <p>
          All content on the Site, including text, graphics, logos, and software, is the property of Game Asset Finder or its licensors and is protected by intellectual property laws.
        </p>

        <h3 className="text-xl font-semibold mt-4 mb-2">6.2. User Content</h3>
        <p>
          You retain ownership of any content you submit, such as search queries and favorited assets. By submitting content, you grant us a non-exclusive, royalty-free license to use, reproduce, and distribute it as necessary to provide our services.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2">7. Scraped Assets Usage</h2>
        <p>
          The assets scraped and provided through our services are for your personal or commercial use, subject to the licenses specified by the original asset providers. You are responsible for ensuring compliance with all applicable licenses and usage rights.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2">8. Termination</h2>
        <p>
          We reserve the right to suspend or terminate your account and access to the Site at our sole discretion, without notice, for conduct that we believe violates these Terms or is harmful to other users or our business.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2">9. Disclaimers and Limitation of Liability</h2>

        <h3 className="text-xl font-semibold mt-4 mb-2">9.1. Disclaimers</h3>
        <p>
          Our services are provided "as is" without warranties of any kind. We do not guarantee the accuracy, reliability, or completeness of the scraped assets.
        </p>

        <h3 className="text-xl font-semibold mt-4 mb-2">9.2. Limitation of Liability</h3>
        <p>
          In no event shall Game Asset Finder be liable for any indirect, incidental, special, consequential, or punitive damages arising out of your use of our services.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2">10. Indemnification</h2>
        <p>
          You agree to indemnify, defend, and hold harmless Game Asset Finder and its affiliates from any claims, losses, liabilities, damages, expenses, and costs arising from your use of the Site or violation of these Terms.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2">11. Governing Law</h2>
        <p>
          These Terms shall be governed by and construed in accordance with the laws of New York State, USA, without regard to its conflict of law principles.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2">12. Changes to Terms</h2>
        <p>
          We may update these Terms from time to time. Any changes will be posted on this page with an updated effective date. Continued use of the Site constitutes acceptance of the updated Terms.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2">13. Contact Us</h2>
        <p>
          If you have any questions about these Terms, please contact us at:
        </p>
        <ul className="list-disc list-inside mb-4">
          <li><strong>Email:</strong> <a href="mailto:support@gameassetfinder.com" className="text-primary-light dark:text-primary-dark underline">support@gameassetfinder.com</a></li>
        </ul>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2">14. Compliance with New York Laws</h2>
        <p>
          You agree to comply with all applicable laws, statutes, ordinances, and regulations of the State of New York in connection with your use of the Site and services.
        </p>
      </section>
    </div>
  );
};

export default TermsPage;