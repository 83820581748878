import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '../components/ui/Button';
import { Input } from '../components/ui/Input';
import { AuthContext } from '../context/AuthContext';
import axios from 'axios';
import { Gamepad2 } from 'lucide-react';

const UpdatePasswordPage: React.FC = () => {
  const [newPassword, setNewPassword] = useState<string>('');
  const [confirmNewPassword, setConfirmNewPassword] = useState<string>('');
  const [message, setMessage] = useState<string>('');
  const [error, setError] = useState<string>('');
  const navigate = useNavigate();
  const { accessToken } = useContext(AuthContext);

  const handleUpdatePassword = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError('');
    setMessage('');

    if (newPassword !== confirmNewPassword) {
      setError('New passwords do not match.');
      return;
    }

    try {
      const response = await axios.post(`${process.env.REACT_APP_ENVIRONMENT_DOMAIN}/user/change-password`, {
        newPassword,
        confirmNewPassword,
      }, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      setMessage(response.data.message);
      setTimeout(() => navigate('/login'), 2000);
    } catch (err: any) {
      setError('Failed to update password: ' + (err.response?.data?.error || err.message));
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-light dark:bg-gradient-dark">
      <div className="bg-white dark:bg-gray-800 p-8 rounded-lg shadow-lg w-full max-w-md">
        <div className="text-center mb-6">
          <Gamepad2 className="mx-auto h-12 w-12 text-primary-light dark:text-primary-dark" />
          <h2 className="text-2xl font-bold text-secondary-light dark:text-secondary-dark">Update Password</h2>
        </div>
        {message && <p className={`text-center mb-4 ${message.includes('successfully') ? 'text-green-500' : 'text-red-500'}`}>{message}</p>}
        {error && <p className="text-red-500 text-center mb-4">{error}</p>}
        <form onSubmit={handleUpdatePassword} className="space-y-4">
          {/* Removed current password input */}
          <div className="mb-4">
            <Input
              type="password"
              placeholder="New Password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              required
              className="bg-white dark:bg-gray-700"
            />
          </div>
          <div className="mb-4">
            <Input
              type="password"
              placeholder="Confirm New Password"
              value={confirmNewPassword}
              onChange={(e) => setConfirmNewPassword(e.target.value)}
              required
              className="bg-white dark:bg-gray-700"
            />
          </div>
          <Button type="submit" className="w-full bg-primary-light dark:bg-primary-dark hover:bg-primary-light dark:hover:bg-primary-dark text-white">
            Update Password
          </Button>
        </form>
      </div>
    </div>
  );
};

export default UpdatePasswordPage;