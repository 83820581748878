import React, { useState } from 'react';
import StripeCheckoutButton from './ui/StripeCheckoutButton';

interface SubscriptionCardProps {
  tier: 'paid' | 'premium';
  title: string;
  basePrice: number; // Base price for 1 month
  tokens: number;
  features: string[];
  currentTier?: string;
  disabled?: boolean;
}

const priceIdMapping: Record<string, string> = {
  'paid_1month': process.env.STRIPE_PAID_PRICE_1MONTH!,
  'paid_6months': process.env.STRIPE_PAID_PRICE_6MONTHS!,
  'paid_yearly': process.env.STRIPE_PAID_PRICE_YEARLY!,

  'premium_1month': process.env.STRIPE_PREMIUM_PRICE_1MONTH!,
  'premium_6months': process.env.STRIPE_PREMIUM_PRICE_6MONTHS!,
  'premium_yearly': process.env.STRIPE_PREMIUM_PRICE_YEARLY!,
};

const priceMapping: Record<string, number> = {
  'paid_1month': 3,
  'paid_6months': 12,
  'paid_yearly': 20,

  'premium_1month': 5,
  'premium_6months': 25,
  'premium_yearly': 45,
};

const SubscriptionCard: React.FC<SubscriptionCardProps> = ({
  tier,
  title,
  basePrice,
  tokens,
  features,
  currentTier,
  disabled = false
}) => {
  const isCurrentTier = currentTier === tier;
  const [selectedPeriod, setSelectedPeriod] = useState<'1month' | '6months' | 'yearly'>('1month');

  const handlePeriodChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedPeriod(e.target.value as '1month' | '6months' | 'yearly');
  };

  const priceId = priceIdMapping[`${tier}_${selectedPeriod}`];
  const displayPrice = priceMapping[`${tier}_${selectedPeriod}`];

  return (
    <div className={`flex flex-col p-6 bg-white dark:bg-gray-700 rounded-lg shadow-lg transition-transform hover:scale-105 h-full ${isCurrentTier ? 'border-2 border-primary-light dark:border-primary-dark' : ''}`}>
      <h3 className="text-2xl font-bold mb-4 text-secondary-light dark:text-secondary-dark">{title}</h3>
      
      {/* Period Selection */}
      <div className="mb-4">
        <label htmlFor={`${tier}-period`} className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">Subscription Period</label>
        <select
          id={`${tier}-period`}
          value={selectedPeriod}
          onChange={handlePeriodChange}
          className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 dark:border-gray-600 focus:outline-none focus:ring-primary-light dark:focus:ring-primary-dark focus:border-primary-light dark:focus:border-primary-dark sm:text-sm rounded-md bg-white dark:bg-gray-700"
        >
          <option value="1month">1 Month</option>
          <option value="6months">6 Months</option>
          <option value="yearly">Yearly</option>
        </select>
      </div>
      
      {/* Display Price Based on Selected Period */}
      <p className="text-4xl font-bold mb-6 text-primary-light dark:text-primary-dark">
        ${displayPrice}
        {selectedPeriod !== '1month' && `/${selectedPeriod === '6months' ? '6 months' : 'year'}`}
      </p>
      
      <ul className="mb-6 space-y-2 text-gray-700 dark:text-gray-300 flex-grow">
        <li className="font-bold">{`${tokens} Tokens`}</li>
        {features.map((feature, index) => (
          <li key={index} className="flex items-center">
            <svg className="w-5 h-5 text-primary-light dark:text-primary-dark mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
            </svg>
            {feature}
          </li>
        ))}
      </ul>
      
      {/* Stripe Checkout Button */}
      <StripeCheckoutButton 
        tier={tier} 
        priceId={priceId} 
        period={selectedPeriod} 
        disabled={disabled} 
      />
    </div>
  );
};

export default SubscriptionCard;