import React from 'react';
import { Link } from 'react-router-dom';
import BrandLogo from '../assets/brand_logo_youtube.png'; 
import YouTubeIcon from '../assets/Youtube_logo.png';

const AboutPage: React.FC = () => {
  return (
    <div className="w-full min-h-screen px-24 py-12 bg-gradient-light dark:bg-gradient-dark text-gray-800 dark:text-gray-200 flex flex-col items-center">
      <h1 className="text-3xl font-bold mb-6">About Us</h1>
      <p className="mb-4 max-w-2xl text-center">
        Welcome to Game Asset Finder! We empower game developers by simplifying the asset discovery process across the internet. Whether you're an indie developer or part of a large studio, our AI-driven platform ensures you find the perfect assets swiftly and efficiently.
      </p>
      <p className="mb-6 max-w-2xl text-center">
        Our mission is to streamline game development, allowing creators to focus more on innovation and less on asset hunting.
      </p>
      
      {/* Subtle Mention of Another Brand */}
      <p className="mb-8 text-sm text-center text-gray-600 dark:text-gray-400">
        From the creator of <strong>TriggerlyDev</strong>
      </p>

      {/* Brand Image and YouTube Button */}
      <div className="flex flex-col items-center mb-12">
        <img src={BrandLogo} alt="Partner Brand Logo" className="h-32 w-auto mb-4" />
        <a href="https://www.youtube.com/@TriggerlyDev" target="_blank" rel="noopener noreferrer">
          <img src={YouTubeIcon} alt="YouTube Channel" className="h-auto w-12 hover:opacity-80 transition-opacity duration-300" />
        </a>
      </div>
    </div>
  );
};

export default AboutPage;