import React from 'react';
import { Link } from 'react-router-dom';
import { Gamepad2 } from 'lucide-react';

const Footer: React.FC = () => {
  return (
    <footer className="bg-gradient-light dark:bg-gradient-dark text-secondary-light dark:text-secondary-dark py-8">
      <div className="container mx-auto px-4 md:px-6">

        <div className="flex flex-col md:flex-row justify-between">
          
          {/* About Section */}
          <div className="mb-6 md:mb-0">
            <Link to="/" className="flex items-center space-x-2 mb-4">
              <Gamepad2 className="h-6 w-6 text-primary-light dark:text-primary-dark" />
              <span className="text-xl font-bold">Game Asset Finder</span>
            </Link>
            <p className="text-gray-300 dark:text-gray-400 max-w-sm">
              Game Asset Finder helps game developers find the assets across the internet for their projects with ease and efficiency.
            </p>
          </div>
          
          {/* Quick Links */}
          <div className="mb-6 md:mb-0">
            <h3 className="text-lg font-semibold mb-4">Quick Links</h3>
            <ul>
              <li className="mb-2">
                <Link to="/about" className="hover:text-primary-light dark:hover:text-primary-dark">About Us</Link>
              </li>
              <li className="mb-2">
                <Link to="/terms" className="hover:text-primary-light dark:hover:text-primary-dark">Terms of Service</Link>
              </li>
              <li className="mb-2">
                <Link to="/privacy" className="hover:text-primary-light dark:hover:text-primary-dark">Privacy Policy</Link>
              </li>
              <li className="mb-2">
                <Link to="/account" className="hover:text-primary-light dark:hover:text-primary-dark">My Account</Link>
              </li>
              <li className="mb-2">
                <Link to="/dashboard" className="hover:text-primary-light dark:hover:text-primary-dark">Dashboard</Link>
              </li>
            </ul>
          </div>
          
          {/* Social Media */}
          <div>
            <h3 className="text-lg font-semibold mb-4">Follow Us</h3>
            <div className="flex space-x-4">
              <a href="https://twitter.com" target="_blank" rel="noopener noreferrer" className="hover:text-primary-light dark:hover:text-primary-dark">
                Twitter
              </a>
              <a href="https://facebook.com" target="_blank" rel="noopener noreferrer" className="hover:text-primary-light dark:hover:text-primary-dark">
                Facebook
              </a>
              <a href="https://instagram.com" target="_blank" rel="noopener noreferrer" className="hover:text-primary-light dark:hover:text-primary-dark">
                Instagram
              </a>
            </div>
          </div>
        </div>
        
        {/* Bottom Footer */}
        <div className="mt-8 border-t border-gray-600 dark:border-gray-700 pt-4 text-center text-sm text-gray-400 dark:text-gray-500">
          © {new Date().getFullYear()} Game Asset Finder. All rights reserved.
        </div>
      </div>
    </footer>
  );
};

export default Footer;