import React, { useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import { Gamepad2, Sun, Moon } from 'lucide-react';
import { ThemeContext } from '../context/ThemeContext';
import FavoritesPopup from './FavoritesPopup';

const Navbar: React.FC = () => {
  const { accessToken, logout } = useContext(AuthContext);
  const { theme, toggleTheme } = useContext(ThemeContext);
  const navigate = useNavigate();

  const [isFavoritesOpen, setIsFavoritesOpen] = useState<boolean>(false);

  const handleFavoritesClick = () => {
    setIsFavoritesOpen(true);
  };

  const closeFavorites = () => {
    setIsFavoritesOpen(false);
  };

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  return (
    <header className="sticky top-0 z-50 w-full border-b bg-gradient-light dark:bg-gradient-dark backdrop-blur">
      <div className="container mx-auto flex h-16 items-center justify-between px-4">
        <Link to="/" className="flex items-center space-x-2">
          <img src="/logo512.png" alt="Game Asset Finder Logo" className="h-6 w-6" />
          <span className="font-bold text-secondary-light dark:text-secondary-dark">Game Asset Finder</span>
        </Link>
        <nav className="flex items-center space-x-4">
          <Link to="/about" className="text-sm font-medium text-secondary-light dark:text-secondary-dark hover:text-primary-light dark:hover:text-primary-dark">
            About Us
          </Link>
          <Link to="/terms" className="text-sm font-medium text-secondary-light dark:text-secondary-dark hover:text-primary-light dark:hover:text-primary-dark">
            Terms
          </Link>
          <Link to="/privacy" className="text-sm font-medium text-secondary-light dark:text-secondary-dark hover:text-primary-light dark:hover:text-primary-dark">
            Privacy Policy
          </Link>
          <Link to="/search" className="text-sm font-medium text-secondary-light dark:text-secondary-dark hover:text-primary-light dark:hover:text-primary-dark">
            Search
          </Link>
          {accessToken ? (
            <>
              <Link to="/dashboard" className="text-sm font-medium text-secondary-light dark:text-secondary-dark hover:text-primary-light dark:hover:text-primary-dark">
                Dashboard
              </Link>
              <Link to="/account" className="text-sm font-medium text-secondary-light dark:text-secondary-dark hover:text-primary-light dark:hover:text-primary-dark">
                Account
              </Link>
              <button onClick={handleLogout} className="text-sm font-medium text-secondary-light dark:text-secondary-dark hover:text-red-500">
                Logout
              </button>
              <button
                  onClick={handleFavoritesClick}
                  className="text-sm font-medium text-secondary-light dark:text-secondary-dark hover:text-primary-light dark:hover:text-primary-dark"
                >
                  Favorites
              </button>
            </>
          ) : (
            <>
              <Link to="/login" className="text-sm font-medium text-secondary-light dark:text-secondary-dark hover:text-primary-light dark:hover:text-primary-dark">
                Login
              </Link>
              <Link to="/signup" className="text-sm font-medium text-secondary-light dark:text-secondary-dark hover:text-primary-light dark:hover:text-primary-dark">
                Sign Up
              </Link>
            </>
          )}
          {/* Theme Toggle */}
          <div className="ml-4">
            <label htmlFor="theme-toggle" className="flex items-center cursor-pointer">
              <div className="relative">
                <input id="theme-toggle" type="checkbox" className="sr-only" checked={theme === 'dark'} onChange={toggleTheme} />
                <div className="w-10 h-4 bg-gray-400 rounded-full shadow-inner dark:bg-gray-600"></div>
                <div
                  className={`dot absolute w-6 h-6 bg-white rounded-full shadow -left-1 -top-1 transition-transform duration-300 ${
                    theme === 'dark' ? 'transform translate-x-full bg-primary-light' : 'bg-secondary-light'
                  }`}
                >
                  {theme === 'dark' ? <Moon className="h-4 w-4 text-gray-800 absolute inset-x-1 inset-y-1" /> : <Sun className="h-4 w-4 text-yellow-400 absolute inset-x-1 inset-y-1" />}
                </div>
              </div>
            </label>
          </div>
        </nav>
      </div>
    </header>
  );
};

export default Navbar;