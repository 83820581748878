import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import { Button } from '../components/ui/Button';
import { Input } from '../components/ui/Input';
import axios from 'axios';

const AccountPage: React.FC = () => {
  const { accessToken, logout, user } = useContext(AuthContext);
  const navigate = useNavigate();

  const [message, setMessage] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [is2FAEnabled, setIs2FAEnabled] = useState<boolean>(false);
  const [qrCode, setQrCode] = useState<string>('');
  const [verifyToken, setVerifyToken] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (user) {
      setIs2FAEnabled(user.is_2fa_enabled);
    }
  }, [user]);

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  const enable2FA = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${process.env.REACT_APP_ENVIRONMENT_DOMAIN}/user/2fa/generate-secret`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });
      setQrCode(response.data.qrCode);
      setLoading(false);
    } catch (err: any) {
      console.error('Enable 2FA Error:', err.response?.data?.error || err.message);
      setError(err.response?.data?.error || 'Failed to generate 2FA secret.');
      setLoading(false);
    }
  };

  const disable2FA = async () => {
    setLoading(true);
    try {
      const response = await axios.post(`${process.env.REACT_APP_ENVIRONMENT_DOMAIN}/user/2fa/disable`, { token: verifyToken }, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });
      setMessage(response.data.message);
      setIs2FAEnabled(false);
      setVerifyToken('');
      setLoading(false);
    } catch (err: any) {
      console.error('Disable 2FA Error:', err.response?.data?.error || err.message);
      setError(err.response?.data?.error || 'Failed to disable 2FA.');
      setLoading(false);
    }
  };

  return (
    <div className="container mx-auto py-8 bg-background-light dark:bg-background-dark text-gray-800 dark:text-gray-200">
      <h1 className="text-3xl font-bold mb-8 text-secondary-light dark:text-secondary-dark">Account Settings</h1>

      {/* Change Password Section 
      <div className="mb-8">
        <h2 className="text-xl font-semibold mb-4 text-secondary-light dark:text-secondary-dark">Change Password</h2>
        <Button
          onClick={() => navigate('/update-password')}
          className="bg-primary-light dark:bg-primary-dark hover:bg-primary-light dark:hover:bg-primary-dark text-white"
        >
          Reset Password
        </Button>
      </div>
*/}

    {/* 2FA Section */}
    <div className="mb-8">
        <h2 className="text-xl font-semibold mb-4 text-secondary-light dark:text-secondary-dark">Two-Factor Authentication</h2>
        {is2FAEnabled ? (
          <>
            <p>2FA is enabled. Please enter your token to disable it.</p>
            <Input
              type="text"
              value={verifyToken}
              onChange={(e) => setVerifyToken(e.target.value)}
              placeholder="Enter the 6-digit code to disable"
              required
              className="bg-white dark:bg-gray-700 mb-2"
            />
            <Button onClick={disable2FA} className="bg-red-600 hover:bg-red-500 text-white" disabled={loading}>
              {loading ? 'Disabling...' : 'Disable 2FA'}
            </Button>
          </>
        ) : (
          <Button onClick={enable2FA} className="bg-primary-light dark:bg-primary-dark hover:bg-primary-light dark:hover:bg-primary-dark text-white">
            Enable 2FA
          </Button>
        )}
        {error && <p className="text-red-500 mt-2">{error}</p>}
        {message && <p className="text-green-500 mt-2">{message}</p>}
      </div>

      {/* Manage Subscriptions Section */}
      <div className="mb-8">
        <h2 className="text-xl font-semibold mb-4 text-secondary-light dark:text-secondary-dark">Manage Subscription</h2>
        <Button
          onClick={() => navigate('/dashboard')}
          className="bg-primary-light dark:bg-primary-dark hover:bg-primary-light dark:hover:bg-primary-dark text-white"
        >
          View or Change Your Subscription
        </Button>
      </div>

      {/* Logout Section */}
      <div className="mb-8">
        <Button
          onClick={handleLogout}
          className="bg-red-600 hover:bg-red-500 text-white"
        >
          Logout
        </Button>
      </div>
    </div>
  );
};

export default AccountPage;