import React from 'react';

const PrivacyPage: React.FC = () => {
  return (
    <div className="w-full min-h-screen px-24 py-12 bg-gradient-light dark:bg-gradient-dark text-gray-800 dark:text-gray-200">
      <h1 className="text-3xl font-bold mb-6">Privacy Policy</h1>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2">1. Introduction</h2>
        <p>
          Welcome to <strong>Game Asset Finder</strong> ("we," "us," "our"). We are committed to protecting your privacy and ensuring the security of your personal information. This Privacy Policy outlines how we collect, use, disclose, and safeguard your information when you visit our website <a href="https://www.gameassetfinder.com" className="text-primary-light dark:text-primary-dark underline">www.gameassetfinder.com</a> (the "Site"). This policy is compliant with the New York Privacy Laws, including the SHIELD Act.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2">2. Information We Collect</h2>
        <p>
          We may collect the following types of information:
        </p>
        <ul className="list-disc list-inside mb-4">
          <li><strong>Personal Data:</strong> Information that can be used to identify you, such as name, email address, and contact details.</li>
          <li><strong>Usage Data:</strong> Information about how you interact with our Site, including pages visited and actions taken.</li>
          <li><strong>Cookies and Tracking Technologies:</strong> Data collected through cookies and similar technologies to enhance your experience.</li>
        </ul>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2">3. How We Use Your Information</h2>
        <p>
          We use the collected information for various purposes, including:
        </p>
        <ul className="list-disc list-inside mb-4">
          <li>To provide and maintain our services.</li>
          <li>To improve and personalize your experience.</li>
          <li>To communicate with you, including responding to your inquiries.</li>
          <li>To process transactions and manage subscriptions.</li>
          <li>To comply with legal obligations.</li>
        </ul>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2">4. Subscriptions and Payments</h2>
        <p>
          Information related to subscriptions and payments is processed securely through Stripe. We do not store your payment information. By providing payment details, you agree to Stripe’s <a href="https://stripe.com/privacy" className="text-primary-light dark:text-primary-dark underline">Privacy Policy</a> and <a href="https://stripe.com/legal" className="text-primary-light dark:text-primary-dark underline">Terms of Service</a>.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2">5. Data Security</h2>
        <p>
          We implement a variety of security measures to maintain the safety of your personal information, including:
        </p>
        <ul className="list-disc list-inside mb-4">
          <li><strong>Encryption:</strong> SSL/TLS encryption for data in transit.</li>
          <li><strong>Secure Storage:</strong> Passwords are hashed and stored securely in Supabase.</li>
          <li><strong>Access Controls:</strong> Restricted access to personal data to authorized personnel only.</li>
          <li><strong>Data Breach Response:</strong> In compliance with the New York SHIELD Act, we have procedures in place to notify you in the event of a data breach.</li>
        </ul>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2">6. Sharing Your Information</h2>
        <p>
          We do not sell, trade, or otherwise transfer your personally identifiable information to outside parties except as described below:
        </p>
        <ul className="list-disc list-inside mb-4">
          <li><strong>Service Providers:</strong> We may share your information with trusted third-party services that assist us in operating our Site and conducting our business.</li>
          <li><strong>Legal Requirements:</strong> We may disclose your information if required by law or in response to valid requests by public authorities.</li>
          <li><strong>Business Transfers:</strong> In the event of a merger, acquisition, or sale of all or a portion of our assets, your information may be transferred as part of that transaction.</li>
        </ul>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2">7. Cookies and Tracking Technologies</h2>
        <p>
          We use cookies and similar tracking technologies to track activity on our Site and hold certain information. You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2">8. Your Rights</h2>
        <p>
          Depending on your location, you may have the following rights regarding your personal data:
        </p>
        <ul className="list-disc list-inside mb-4">
          <li><strong>Access:</strong> Request access to your personal information.</li>
          <li><strong>Correction:</strong> Request correction of inaccurate or incomplete information.</li>
          <li><strong>Deletion:</strong> Request deletion of your personal information.</li>
          <li><strong>Restriction:</strong> Request restriction of processing your personal information.</li>
          <li><strong>Data Portability:</strong> Request transfer of your personal information to another party.</li>
          <li><strong>Objection:</strong> Object to processing of your personal information.</li>
        </ul>
        <p>
          To exercise any of these rights, please contact us using the contact information provided below.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2">9. Children's Privacy</h2>
        <p>
          Our Site is not intended for individuals under the age of 13. We do not knowingly collect personal information from children under 13. If you become aware that a child has provided us with personal information, please contact us immediately.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2">10. Changes to This Privacy Policy</h2>
        <p>
          We may update our Privacy Policy from time to time. Any changes will be posted on this page with an updated effective date. Continued use of the Site constitutes acceptance of the updated Privacy Policy.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2">11. Compliance with New York Laws</h2>
        <p>
          We adhere to all applicable New York State laws regarding data protection and privacy, including the Stop Hacks and Improve Electronic Data Security Act (SHIELD Act). This includes implementing reasonable administrative, technical, and physical safeguards to protect your personal information.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2">12. Contact Us</h2>
        <p>
          If you have any questions about this Privacy Policy, please contact us at:
        </p>
        <ul className="list-disc list-inside mb-4">
          <li><strong>Email:</strong> <a href="mailto:support@gameassetfinder.com" className="text-primary-light dark:text-primary-dark underline">support@gameassetfinder.com</a></li>
        </ul>
      </section>
    </div>
  );
};

export default PrivacyPage;