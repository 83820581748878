import React, { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import axios from 'axios';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY!);

interface StripeCheckoutButtonProps {
  tier: 'paid' | 'premium' | 'bundle_500' | 'bundle_1000' | 'bundle_2500';
  priceId: string;
  period?: '1month' | '6months' | 'yearly';
  disabled?: boolean;
}

const StripeCheckoutButton: React.FC<StripeCheckoutButtonProps> = ({ tier, priceId, period, disabled = false }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleCheckout = async () => {
    try {
      setLoading(true);
      setError(null);
      
      // Prepare payload
      const payload: any = { subscriptionTier: tier, priceId };
      if (!tier.startsWith('bundle') && period) {
        payload.period = period;
      }
  
      const accessToken = localStorage.getItem('accessToken');
      if (!accessToken) {
        throw new Error('User is not authenticated.');
      }
  
      const response = await axios.post(
        `${process.env.REACT_APP_ENVIRONMENT_DOMAIN}/stripe/checkout`, 
        payload,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`,
          }
        }
      );
  
      const { sessionId } = response.data;
  
      const stripe = await stripePromise;
      if (!stripe) throw new Error('Stripe failed to initialize');
  
      const { error } = await stripe.redirectToCheckout({ 
        sessionId
      });
  
      if (error) {
        console.error('Stripe Redirect Error Details:', { error });
        throw error;
      }
  
    } catch (err: any) {
      console.error('Checkout Error:', err.response?.data || err.message);
      setError(err.response?.data?.message || 'Checkout failed');
    } finally {
      setLoading(false);
    }
  };

  const getButtonText = () => {
    if (loading) return 'Processing...';
    switch(tier) {
      case 'paid':
        return 'Upgrade to Paid';
      case 'premium':
        return 'Upgrade to Premium';
      case 'bundle_500':
        return 'Buy 500 Tokens';
      case 'bundle_1000':
        return 'Buy 1000 Tokens';
      case 'bundle_2500':
        return 'Buy 2500 Tokens';
      default:
        return 'Checkout';
    }
  };

  return (
    <div>
      <button
        onClick={handleCheckout}
        disabled={loading || disabled}
        className={`w-full p-2 bg-primary-light dark:bg-primary-dark text-white rounded hover:bg-primary-light dark:hover:bg-primary-dark transition duration-300 ${
          (loading || disabled) && 'opacity-50 cursor-not-allowed'
        }`}
      >
        {getButtonText()}
      </button>
      {error && <p className="text-red-500 text-sm mt-2">{error}</p>}
    </div>
  );
};

export default StripeCheckoutButton;